import React, { useEffect } from "react";

import { SessionType } from "@/autoGeneratedGlobalTypes";
import Loader from "@/components/common/loader";
import useCreateSession from "@/hooks/useCreateSession";
import { useMarketingHook } from "@/marketing/marketingHook";
import { isBrowser } from "@/utils/env";
import { getSessionTypeFromUrl } from "@/utils/urlUtils";

import "../common.scss";

interface SessionPageProps {
   location: {
    state: null | {
      sessionType : SessionType
    }
  };
}

const SessionPage = ({ location }: SessionPageProps) => {
  const sessionType = getSessionTypeFromUrl();
  const [createVideoSession] = useCreateSession({
    sessionType: SessionType.VIDEO_CALL,
    rewriteUrl: true,
  });
  const [createChatSession] = useCreateSession({
    sessionType: SessionType.CHAT,
    rewriteUrl: true,
  });
  const { reachCustomYandexMetricsGoal } = useMarketingHook();

  useEffect(() => {
    if (!isBrowser()) {
      return undefined;
    }
    const urlParams = new URLSearchParams(window.location.search.replace("?", ""));
    const expertId = parseInt(urlParams.get("expert_id") ?? "", 10) ?? undefined;
    if (expertId) {
      const gagTimer = setTimeout(
        () => {
          if (!sessionType) {
            return;
          }

          switch (sessionType) {
            case SessionType.CHAT:
              createChatSession(expertId);
              break;
            case SessionType.VIDEO_CALL:
            default: {
              createVideoSession(expertId);
            }
          }
        },
        2000,
      );
      return () => {
        clearTimeout(gagTimer);
      };
    }
    return undefined;
  }, [createChatSession, createVideoSession, sessionType]);

  useEffect(() => {
    reachCustomYandexMetricsGoal("session-page", { sessionType });
  }, [reachCustomYandexMetricsGoal, sessionType]);

  if (!isBrowser()) {
    return undefined;
  }

  return (
    <Loader style={{ marginTop: "20px" }} />
  );
};

export default SessionPage;
